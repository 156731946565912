import React from 'react'
import macbook from '../images/macbook.png'

const Certificate = () => {
  return (
    <section className='flex flex-col items-center justify-center py-12 lg:py-28 bg-gray-100 gap-4 lg:gap-8 font-urbanist'>
      <h2 className=' font-bold text-2xl text-center lg:text-5xl text-gray-900 mb-4 lg:mb-12 mx-4'>A vásárlást követően elküldjük neked a tanúsítványod.</h2>
      <img alt="tanusitvany" src={macbook} className="max-w-[80vw]"></img>
      <p className='text-center lg:text-xl mx-4'>Hiteles tanúsítvány, amit kitehetsz a weboldalarda vagy a faladra</p>
    </section>
  )
}

export default Certificate
