import React from 'react'

const ErrorPage = () => {
  return (
    <main className="flex flex-col justify-center items-center h-screen bg-gradient-to-b from-emerald-900 to-emerald-700 text-gray-100 gap-5 px-8">
      <h1 className="text-center text-xl md:text-4xl ">
        Az oldal nem létezik vagy nem elérhető
      </h1>
      <button
        className="mt-4 py-4 px-8  bg-indigo-600 text-gray-100 font-bold cursor-pointer rounded-md border-2 border-indigo-600 hover:bg-transparent hover:text-indigo-600 transition duration-300 ease-in-out"
        onClick={() => window.location.href='https://www.karbonkvota.hu'}
      >
        Vissza a fődalra
      </button>
    </main>
  )
}

export default ErrorPage