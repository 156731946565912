import React from "react";

const Prices = () => {
  return (
<section className="text-gray-900 flex flex-col items-center bg-stone-200">
  <h2
    id="pricing"
    className="text-center font-urbanist font-bold text-xl lg:text-4xl my-8 lg:my-24"
  >
    Csomagjaink
  </h2>
  <div className="pb-12 w-full relative bg-gray-100">
    <div className="before:content-[''] before:absolute before:bg-stone-200 before:w-full before:h-1/2 before:top-0 before:z-10"></div>
    <div className="mx-auto font-sans w-64 lg:w-80 bg-white rounded-md p-6 lg:p-10 shadow-md flex flex-col items-center justify-center relative z-20">
      <h3 className="text-base lg:text-2xl font-semibold py-4">
        Feltöltés alatt
      </h3>
      <p className="text-sm lg:text-xl text-center py-4 border-y-2 border-indigo-600">
        Csomagjaink hamarosan megérkeznek. Addig is, vásárolhatsz
        széndioxid-semlegesítést e-mailben, mindössze 10,8 forintos áron.
      </p>
      <div className="flex flex-col gap-0 py-4 ">
        <p className="font-bold">E-mail:</p>
        <p>info@karbonkvota.hu</p>
      </div>
    </div>
  </div>
</section>
  );
};

export default Prices;
