import React from 'react'
import PrivacyPolicyElement from '../components/PrivacyPolicyElement'
import Faq from '../components/Faq'


const PrivacyPolicy = () => {
  return (
    <main className='min-h-screen'>
      <PrivacyPolicyElement />
      <Faq />
    </main>
  )
}

export default PrivacyPolicy