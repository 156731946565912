import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import LawPolicy from "./pages/LawPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Navbar from "./components/Navbar";
import ErrorPage from "./pages/ErrorPage";
import WelcomeModal from "./components/WelcomeModal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <WelcomeModal />
    <Navbar />
      <Routes>
        <Route>
          <Route index element={<HomePage />} />
          <Route path="/kapcsolat" element={<Contact />} />
          <Route path="/jogi-nyilatkozat" element={<LawPolicy />} />
          <Route path="/adatvedelmi-nyilatkozat" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
