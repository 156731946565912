import React, { useEffect } from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import { HiArrowLongLeft } from "react-icons/hi2";
import logo48 from "../images/logo48.png";
import DynamicArrow from "./DynamicArrow";

const Working = () => {
  useEffect(() => {
    const container = document.querySelector(".scroll-container");
    if (container) {
      const containerWidth = container.scrollWidth;
      const viewportWidth = container.clientWidth;
      container.scrollLeft = (containerWidth - viewportWidth) / 2;
    }
  }, []);
  return (
    <section className="min-w-full bg-stone-200 py-12 lg:py-28 text-gray-900">
      <h2 className="text-center font-urbanist font-bold text-xl lg:text-4xl mb-8 lg:mb-24 ">
        Hogyan működünk?
      </h2>
      <div className="overflow-x-auto px-8 scroll-container text-sm lg:text-base">
        <div className="inline-flex justify-center lg:gap-6 font-sans min-w-full">
          <ul className="w-52 lg:w-60  bg-white rounded-md p-6 lg:p-10 shadow-md flex flex-col items-center justify-center gap-4 min-w-[208px] lg:min-w-[240px]">
            <li className="flex flex-col items-center">
              <b>Cég</b>
              <p>54.000 FT</p>
            </li>
            <li className="flex flex-col items-center">
              <b>Web Shop</b>
              <p>10.800 FT</p>
            </li>
            <li className="flex flex-col items-center">
              <b>Magánszemély</b>
              <p>21.600 FT</p>
            </li>
          </ul>
          <div className="flex flex-col justify-center items-center min-w-[240px] ">
            <p className="  ">HUF</p>
            <DynamicArrow direction={1} />
            <DynamicArrow direction={0} />
            <p className="  ">Tanúsítvány</p>
          </div>
          <div className=" w-64 lg:w-80 bg-white rounded-md p-6 lg:p-10 shadow-md flex flex-col items-center justify-center min-w-[256px] lg:min-w-[288px]">
            <h3 className="font-bold flex items-center justify-center gap-2">
              <img alt="logo" src={logo48} className="h-8"></img>Karbon Kvóta
            </h3>
            <br></br>
            <p className="text-center">
              Ökoton (görög öko, az ökológiából + tonosz, feszültség) két
              társulás, ökorégió határán kialakuló zóna. Kapcsolódást hozunk
              létre azok között, akik tenni akarnak a földünkért és a
              karbonsemlegesítő cégek között. Segítjük a kar bonlábnyom
              csökkentését és támogatjuk a zöld kezdeményezéseket.
            </p>
          </div>

          <div className="flex flex-col justify-center items-center min-w-[240px] ">
            <p className="  ">$</p>
            <DynamicArrow direction={1} />
            <DynamicArrow direction={0} />
            <p className="  ">Riport</p>
          </div>
          <ul className="w-52 lg:w-60  bg-white rounded-md p-6 lg:p-10 shadow-md flex flex-col items-center justify-center gap-4 min-w-[208px] lg:min-w-[240px]">
            <li className="flex flex-col items-center">
              <b>Zöld partner 1</b>
              <p>Kivonva: 240 t CO2</p>
            </li>
            <li className="flex flex-col items-center">
              <b>Zöld partner 2</b>
              <p>Kivonva: 140 t CO2</p>
            </li>
            <li className="flex flex-col items-center">
              <b>Zöld partner 3</b>
              <p>Kivonva: 80 t CO2</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Working;
