import React from "react";
import { Link } from "react-router-dom";

const LawPolicyElement = () => {
  return (
    <section className=" flex flex-col items-center py-16 bg-gray-100 gap-4 lg:gap-8 text-gray-900">
      <h2 className="text-xl lg:text-4xl font-bold font-urbanist mb-4 lg:mb-8">
        Jogi nyilatkozat
      </h2>
      <ul className="font-sans lg:text-xl">
        <li className="flex gap-2">
          <p className="font-bold">Domain:</p>
          <Link to="/" className="text-blue-700 hover:underline">
            karbonkvota.hu
          </Link>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">E-mail:</p>
          <p>info@karbonkvota.hu</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Telefonszám:</p>
          <p>+36-20-559-1243</p>
        </li>
        <br></br>
        <li className="flex gap-2">
          <p className="font-bold">Adószám:</p>
          <p>27716368-1-13</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Cégjegyzékszám:</p>
          <p>13-09-217696</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Cégnév:</p>
          <p>Karbon Kvóta Kft.</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Cím:</p>
          <p>2192 Hévízgyörk, Széchenyi utca 42.</p>
        </li>
        <br></br>
        <p className="font-bold">Tárhely szolgáltató:</p>
        <br></br>
        <li className="flex gap-2">
          <p className="font-bold">Cégnév:</p>
          <p>Zoltan Egri FL 434896</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Address:</p>
          <p>United Arab Emirates, Dubai</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Licence Number:</p>
          <p>DMCC-926796</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Mobil:</p>
          <p>+971582673435</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">E-mail:</p>
          <p>support@egri.ae</p>
        </li>
      </ul>
    </section>
  );
};

export default LawPolicyElement;
