import React, { useState, useEffect } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { HiArrowLongLeft } from "react-icons/hi2";

const DynamicArrow = ({direction}) => {
    const [size, setSize] = useState(80);

    useEffect(() => {
        const handleResize = () => {
            const newSize = window.innerWidth >= 1024 ? 100 : 50;
            setSize(newSize);
        };

        handleResize();

        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        direction === 1 ?
            <HiArrowLongRight size={size} color="rgb(17,24,39)" />
            :
            <HiArrowLongLeft size={size} color="rgb(17,24,39)" />
    );
};

export default DynamicArrow;