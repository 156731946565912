import React from 'react'
import { FaChevronRight } from "react-icons/fa";
import green from "../images/green.png";

const Hero = () => {
  const scrollToPricing = () => {


      const pricingElement = document.getElementById('pricing');
      if (pricingElement) {
        pricingElement.scrollIntoView({ behavior: 'smooth' });
      }

  };
  return (
    <section className='bg-gradient-to-b from-emerald-900 to-emerald-700 flex flex-col xl:flex-row justify-center items-center gap-8 py-10 lg:py-20 '>
                <div className=" flex flex-col justify-center gap-10 lg:gap-20 text-gray-100 mx-5">
          <h1 className="text-2xl md:text-5xl font-urbanist max-w-md font-bold">
            Kössünk meg együtt 1.000.000 tonna szén-dioxidot.
          </h1>
          <h2 className="font-sans text-lg md:text-lg max-w-md">
          Semelegesísd szén-dioxid kibocsátásod kevesebb mint 11 Ft / Kg áron
          </h2>
          <button onClick={scrollToPricing}
            className="px-6 py-4 bg-indigo-500 text-sm font-sans rounded-xl hover:scale-105 transition duration-300 ease-in-out flex justify-center items-center gap-3"
          >
            Vásárlás <FaChevronRight size={14} />
          </button>
        </div>
        <div className="w-4/5 sm:w-1/2 xl:w-1/3">
          <img alt="" src={green}></img>
        </div>
    </section>
  )
}

export default Hero
