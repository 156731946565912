import React from "react";

const BigNumber = () => {
  return (
    <section className=" bg-stone-200 py-12 lg:py-28 text-gray-900 ">
      <h2 className="text-center font-urbanist font-bold text-xl lg:text-4xl mb-1 lg:mb-2">
        Eddigi Eredményeink
      </h2>
      <p className="font-urbanist text-center font-extralight mb-8 lg:mb-24 text-xs lg:text-sm text-gray-700">(kevesebb mint 1 hónap alatt)</p>
      <ul className="flex flex-col items-center md:flex-row justify-center gap-4 lg:gap-8 mx-2">
        <li className="font-sans flex flex-col gap-2 lg:gap-4 py-8 lg:py-16 border-gray-300 border-2 rounded-xl w-52 lg:w-80">
          <h3 className="text-3xl lg:text-6xl font-extrabold text-center">400t+</h3>
          <p className="lg:text-xl font-thin text-center">Semelegesített CO2</p>
        </li>
        <li className="font-sans flex flex-col gap-2 lg:gap-4 py-8 lg:py-16 border-gray-300 border-2 rounded-xl w-52 lg:w-80">
          <h3 className="text-3xl lg:text-6xl font-extrabold text-center">50+</h3>
          <p className="lg:text-xl font-thin text-center">Szén-dioxid Semelegesítő</p>
        </li>
        <li className="font-sans flex flex-col gap-2 lg:gap-4 py-8 lg:py-16 border-gray-300 border-2 rounded-xl w-52 lg:w-80">
          <h3 className="text-3xl lg:text-6xl font-extrabold text-center">99%</h3>
          <p className="lg:text-xl font-thin text-center">Elégedett vásárló</p>
        </li>
      </ul>
    </section>
  );
};

export default BigNumber;
