import React from 'react';
import pdf from './adatkezeles.pdf';

const PrivacyPolicyElement = () => {
  return (
    <section className="flex flex-col items-center py-16 bg-gray-100 gap-4 lg:gap-8 text-gray-900">
      <h2 className="text-xl lg:text-4xl font-bold font-urbanist mb-4 lg:mb-8">
        Adatvédelmi nyilatkozat
      </h2>
      <div className="w-full max-w-4xl px-4 lg:px-8">
        <iframe
          src={pdf}
          className="w-full h-96 sm:h-screen border-4 border-gray-800 rounded-lg"
          title="Adatvédelmi nyilatkozat"
        />
      </div>
    </section>
  );
};

export default PrivacyPolicyElement;
