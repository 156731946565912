import React from 'react'
import {Link,useNavigate   } from "react-router-dom";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate();

    const scrollToPricing = () => {
      navigate('/');
      setTimeout(() => {
        const pricingElement = document.getElementById('pricing');
        if (pricingElement) {
          pricingElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    };
    const scrollToFaq = () => {
      navigate('/');
      setTimeout(() => {
        const pricingElement = document.getElementById('faq');
        if (pricingElement) {
          pricingElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    };
  return (
    <footer className='font-sans text-gray-100  bg-gray-800 py-2'>
        <ul className='container mx-auto py-6 lg:py-10 flex-wrap flex justify-center items-center text-sm gap-4 lg:gap-8 px-4'>
            <li className='cursor-pointer pb-1 border-b-2 border-transparent hover:border-gray-100'><button onClick={scrollToPricing}>Vásárlás</button></li>
            <li className='cursor-pointer pb-1 border-b-2 border-transparent hover:border-gray-100'><button onClick={scrollToFaq}>GY.I.K</button></li>
            <li className='cursor-pointer pb-1 border-b-2 border-transparent hover:border-gray-100'>
              <Link to="/kapcsolat">Kapcsolat</Link>
              </li>
            <li className='cursor-pointer pb-1 border-b-2 border-transparent hover:border-gray-100'>
            <Link to="/jogi-nyilatkozat">Jogi nyilatkozat</Link>
              </li>
            <li className='cursor-pointer pb-1 border-b-2 border-transparent hover:border-gray-100'>
            <Link to="/adatvedelmi-nyilatkozat">Adatvédelmi nyilatkozat</Link>
              </li>

        </ul>
        <p className='container mx-auto border-t-2-gray-400 border-t-2 border-gray-400 py-6 lg:py-10 text-base text-center'>
        © {currentYear} Karbon Kvóta Kft.
        </p>
    </footer>
  )
}

export default Footer