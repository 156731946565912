import React, { useEffect } from 'react'
import logo48 from "../images/logo48.png";
import {Link,useLocation,useNavigate   } from "react-router-dom";


const Navbar = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [location]);
  const navigate = useNavigate();

  const scrollToPricing = () => {
    navigate('/');
    setTimeout(() => {
      const pricingElement = document.getElementById('pricing');
      if (pricingElement) {
        pricingElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };
  return (
    <header className="bg-emerald-900 ">
        <nav className=' font-sans flex items-center justify-between h-20 px-4 sm:px-8 container mx-auto'>
        <Link
          to="/"
          className="cursor-pointer text-gray-100 font-bold text-xl flex items-center justify-center gap-3"
        >
          <img alt="" src={logo48}></img>
          Karbon Kvóta
        </Link>
        <div className='flex gap-4 text-gray-100 '>
        <button onClick={scrollToPricing} className=' py-2 px-6  bg-indigo-600 text-gray-100 font-bold cursor-pointer rounded-md border-2 border-indigo-600 hover:bg-transparent hover:text-indigo-600 transition duration-300 ease-in-out' >Vásárlás</button>
          <Link to='/kapcsolat' className='hidden sm:block  py-2 px-6 bg-gray-100 text-indigo-600 font-bold cursor-pointer rounded-md border-2 border-gray-100 hover:bg-transparent hover:text-gray-100 transition duration-300 ease-in-out'>Kapcsolat</Link>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
