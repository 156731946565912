import React from "react";
import ContactElement from "../components/ContactElement";
import Faq from "../components/Faq";

const Contact = () => {
  return (
    <main className=" bg-gray-100 ">
      <ContactElement />
      <Faq />
    </main>
  );
};

export default Contact;
