import React from 'react'
import LawPolicyElement from '../components/LawPolicyElement'
import Faq from '../components/Faq'

const LawPolicy = () => {
  return (
    <main className='min-h-screen bg-gray-100'>
      <LawPolicyElement />
      <Faq />
    </main>
  )
}

export default LawPolicy