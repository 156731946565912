import React from "react";
import Working from "../components/Working";
import Certificate from "../components/Certificate";
import Faq from "../components/Faq";
import Prices from "../components/Prices";
import Hero from "../components/Hero";
import BigNumber from "../components/BigNumber";

const HomePage = () => {
  return (
    <main>
      <Hero />
      <BigNumber />
      <Certificate />
      <Working />
      <Prices />
      <Faq />
    </main>
  );
};

export default HomePage;
