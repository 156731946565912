import React, { useState } from "react";
import SingleFaq from "./SingleFaq";
import {Link,useLocation  } from "react-router-dom";


const Faq = () => {
  const locationElement = useLocation();
  const location = locationElement.pathname.toLocaleLowerCase();
  const [faqs, setFaqs] = useState([
    {
      question: "Mennyibe kerül a CO2 semlegesítés?",
      answer: "Jelenleg a CO2 semlegesítés ára 10,8 Ft / kg.",
    },
    {
      question:
        "Milyen előnyökkel jár vállalkozásom számára a CO2 semlegesítés?",
      answer:
        "A CO2 semlegesítés hozzájárulhat vállalkozása hírnevének javításához. A vásárlás után kihelyezheti a tanúsítványunkat weboldalára, amely igazolja környezeti felelősségvállalását és elkötelezettségét a fenntarthatóság iránt.",
      open: false,
    },
    {
      question: "Milyen projektekkel semlegesíthető a CO2?",
      answer:
        "A CO2 semlegesítése különféle projektek révén érhető el, mint például erdőtelepítés, erdőmegőrzés, megújuló energiaforrások fejlesztése, energiahatékonysági programok, és hulladékgazdálkodási projektek",
      open: false,
    },
    {
      question: "Mi történik a vásárlás után?",
      answer:
        "A vásárlás után kapcsolatba lépünk zöld partnereinkkel, akik a CO2 kivonásán dolgoznak. Ezt követően hitelesített tanúsítványt állítunk ki a semlegesítésről, amelyet vállalkozása felhasználhat kommunikációs és marketing célokra.",
      open: false,
    },
  ]);
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <section className="bg-gray-100  text-gray-900 flex flex-col items-center pb-16 lg:pb-32" id="faq">
      <h2 className="text-center font-urbanist font-bold text-xl lg:text-4xl my-8 lg:my-24">
        Gyakran ismételt kérédések
      </h2>
      <ul className="w-10/12 lg:w-8/12 px-4 mx-auto font-sans text-sm lg:text-lg mb-4 lg:mb-12">
        {faqs.map((faq, index) => (
          <SingleFaq
            faq={faq}
            index={index}
            key={index}
            toggleFAQ={toggleFAQ}
          />
        ))}
      </ul>
      {location !== '/kapcsolat' &&
      <div className="w-10/12 lg:w-6/12 px-auto bg-stone-300 rounded-lg p-4 sm:p-8 flex justify-center sm:justify-between items-center flex-wrap gap-4">
        <h3 className="font-sans font-bold  text-sm lg:text-xl">
          Nemtalálod a választ amit keresel?
        </h3>
        <Link
          to="/kapcsolat"
          className=" py-2 px-4 lg:py-4 lg:px-8 bg-indigo-600 text-gray-100 font-bold cursor-pointer rounded-xl"
        >
          Kapcsolat
        </Link>
      </div>
}
    </section>
  );
};

export default Faq;
