import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";

const WelcomeModal = () => {
  const [shownModal, setShownModal] = useState(true);
  useEffect(() => {
    if (shownModal) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      document.body.classList.add("overflow-hidden");
      setTimeout(() => {
        setShownModal(false);
      }, 15000);
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [shownModal]);

  function handleCloseModal() {
    setShownModal(false);
  }
  return (
    <>
      {shownModal && (
        <div className="absolute w-full bg-gray-900 bg-opacity-80 ">
          <div className="flex justify-center items-center h-screen ">
            <div className=" bg-gray-100 px-4 py-8 lg:px-6 lg:py-12 text-gray-900 w-10/12 rounded-lg max-w-screen-md flex flex-col items-center font-sans gap-2 text-center relative">
            <IoCloseOutline className="absolute right-1 sm:right-4 top-1 sm:top-4 cursor-pointer" size={40} onClick={handleCloseModal}/>
              <h2 className="text-center font-urbanist font-bold text-base lg:text-3xl mb-2 lg:mb-4">
                Köszöntünk oldalunkon!
              </h2>
              <p>
                Az értékesítés első fázisa lezárúlt, hamarossan megnyitjuk a
                második értékesítést.
              </p>
              <p>
                Köszönjük az eddigi széndioxid megkötést, szám szerint: több
                mint 400 tonna, 4.000.000 Ft értékben
              </p>
              <p>Külön köszönet az első eun kivüli ügyfélnek (Török)</p>
              <p>Hamarossan kezdjük a második körös értékesítést.</p>
              <button
                onClick={handleCloseModal}
                className="py-2 px-6 mt-2 lg:mt-4  bg-indigo-600 text-gray-100 font-bold cursor-pointer rounded-md border-2 border-indigo-600 hover:bg-indigo-800  transition duration-300 ease-in-out"
              >
                Tovább az oldalra
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeModal;
