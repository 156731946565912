import React from "react";

const SingleFaq = ({ faq, index, toggleFAQ }) => {
  return (
    <li key={index} onClick={() => toggleFAQ(index)} className="cursor-pointer py-4 mb-6 border-b-gray-500 border-b-2">
      <h3 className="font-bold flex justify-between">{faq.question} <span className=" text-base lg:text-xl text-gray-700 font-thin pl-4">{faq.open ? "-":"+"}</span></h3>
      {faq.open && <p className="font-thin mt-4 ">{faq.answer}</p>}
    </li>
  );
};

export default SingleFaq;
