import React, { useState } from "react";
import { Link } from "react-router-dom";

const ContactElement = () => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccesAlert, setShowSuccesAlert] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await fetch("/api/contactMessage.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, subject, message }),
      });
      if (response.ok) {
        displaySucces()
      } else {
        displayError();
      }
    } catch (error) {
      displayError();
    }
    setEmail("");
    setName("");
    setSubject("");
    setMessage("");
  }
  function displaySucces() {
    setShowSuccesAlert(true);
    setTimeout(() => {
      setShowSuccesAlert(false);
    }, 3000);
  }

  function displayError() {
    setShowErrorAlert(true);
    setTimeout(() => {
      setShowErrorAlert(false);
    }, 3000);
  }
  return (
    <section className="flex flex-col items-center py-16 bg-gray-100 gap-4 lg:gap-8 text-gray-900">
      <h2 className="text-xl lg:text-4xl font-bold font-urbanist mb-4 lg:mb-8">
        Kapcsolat
      </h2>
      <ul className="font-sans lg:text-xl  mb-4 lg:mb-8">
        <li className="flex gap-2">
          <p className="font-bold">Domain:</p>
          <Link to="/" className="text-blue-700 hover:underline">
            karbonkvota.hu
          </Link>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">E-mail:</p>
          <p>info@karbonkvota.hu</p>
        </li>
        <li className="flex gap-2">
          <p className="font-bold">Telefonszám:</p>
          <p>+36-20-559-1243</p>
        </li>
      </ul>
      <h2 className="text-xl lg:text-4xl font-bold font-urbanist mb-4 lg:mb-8 ">
        Írj nekünk
      </h2>
      <form
        className="font-sans flex flex-col gap-4 w-8/12 lg:w-4/12 px-auto"
        onSubmit={handleSubmit}
      >
        <div className="flex w-full justify-between">
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={50}
            required
            className="focus:outline-none w-[48%] p-2 rounded-lg font-thin border-2 border-gray-300 text-gray-700"
            placeholder="Teljes név*"
          ></input>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            maxLength={50}
            required
            type="email"
            className="focus:outline-none w-[48%] p-2 rounded-lg font-thin border-2 border-gray-300 text-gray-700"
            placeholder="Email*"
          ></input>
        </div>
        <input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          maxLength={50}
          required
          className="focus:outline-none w-full p-2 rounded-lg font-thin border-2 border-gray-300 text-gray-700"
          placeholder="Tárgy*"
        ></input>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          resize="none"
          className="focus:outline-none w-full p-2 rounded-lg font-thin border-2 border-gray-300 text-gray-700 h-[25vw] max-h-32 align-top"
          placeholder="Üzenet*"
        ></textarea>
        <button
          type="submit"
          className=" py-2 px-4 lg:py-4 lg:px-8 bg-indigo-600 text-gray-100 font-bold cursor-pointer rounded-xl border-2 border-indigo-600 hover:bg-transparent hover:text-indigo-600 transition duration-300 ease-in-out"
        >
          Küldés
        </button>
      </form>
      {showErrorAlert && (
        <div
          className="flex items-center bg-red-700 rounded-lg text-gray-100 text-md font-bold px-4 py-3 absolute top-20 mx-4"
          role="alert"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p>Valmi hiba történt! Próbáld újra.</p>
        </div>
      )}
      {showSuccesAlert && (
        <div
          className="flex items-center bg-green-700 rounded-lg text-gray-100 text-md font-bold px-4 py-3 absolute top-20 mx-4"
          role="alert"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
          </svg>
          <p>Az üzenetet megkaptuk, hamarosan válaszolunk.</p>
        </div>
      )}
    </section>
  );
};

export default ContactElement;
